import {
    BulkExportButton,
    Datagrid,
    DateField, DeleteWithConfirmButton, FunctionField,
    List,
    TextField,
} from "react-admin";

import { SelectField } from 'react-admin';
import {MoneyField} from "../admin/MoneyField";
import {Fragment} from "react";

const TransactionList = () => {

    const BulkActionButtons = () => (
        <Fragment>
            <BulkExportButton />
        </Fragment>
    );

    const DeleteButton = () => {
        return (
            <DeleteWithConfirmButton
                confirmTitle=""
                label="Reverse"
                confirmContent="Do you really want to reverse this transaction?"
                redirect=""
            />
        );
    }

    return (
        <List>
            <Datagrid bulkActionButtons={<BulkActionButtons />} >
                <SelectField source="transaction_type" choices={[
                    { id: "DISBURSMENT", name: "Disbursment" },
                    { id: "REPAYMENT", name: "Repayment" },
                    { id: "INTEREST_WAIVER", name: "Interest waiver" },
                    { id: "PRINCIPAL_WRITEOFF", name: "Principal write off" },
                    { id: "REFUND", name: "Refund" },
                    { id: "CLOSING_REFUND", name: "Closing refund" },
                    { id: "CLOSING_WRITEOFF", name: "Closing principal write off" },
                ]} />
                <DateField source="paid_at" showTime locales="en-UK"/>
                <MoneyField source="amount" />
                <TextField label="Posted by" source="posted_by.username" sortable={false}/>
                <FunctionField label="Reversed" render={(record: { reversed: any}) => `${record.reversed ? "reversed" : "-"}`} />
                <FunctionField label="" render={(record: { reversed: any }) => !record.reversed ? <DeleteButton /> : ""} />
            </Datagrid>
        </List>
    );
};

const Transactions = { list: TransactionList };

export default Transactions;