import * as React from 'react';
import { useRecordContext } from 'ra-core';
import {FunctionField} from "react-admin";
import get from 'lodash/get';

// @ts-ignore
export const PercentField = (props) => {
    const {
        label,
        source,
    } = props;
    const record = useRecordContext(props);
    let value = get(record, source)
    if (value === null)
        return <FunctionField label={label} textAlign="right" render={() => "-"} />
    return <FunctionField label={label} textAlign="right" render={() => Number(value).toFixed(2) + "%"} />
};