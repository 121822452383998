import React from 'react';
import 'dayjs/locale/en-gb';
import {EffectiveDateInput} from "../form_inputs/EffectiveDateInput";
import {BoolDictionary} from "../custom_types/customTypes";
import ConfirmDialog from "./ConfirmDialog";
import {NextPaymentOnOrAfterInput} from "../form_inputs/NextPaymentOnOrAfterInput";
import {ContractedInput} from "../form_inputs/ContractedInput";


const AddPaymentHolidayDialog = (props: { id: string; icon: any; button_type: "list" | "show";
    fields: any }) => {
    const DialogField = (props: { fieldKey: string, onChange: any, error: boolean, value:any }) => {
        if (props.fieldKey === "next_payment_on_or_after") {
            return (<NextPaymentOnOrAfterInput {...props} />)
        }
        if (props.fieldKey === "effective_date") {
            return (<EffectiveDateInput {...props} />)
        }
        return (<ContractedInput {...props} />)
    }

    const getParams = (id: any, values: any) => {
        return {
            data: {
                loan: id,
                next_payment_on_or_after: values['next_payment_on_or_after'],
                effective_date: values['effective_date'],
                contracted: values["contracted"] === null ? false : values["contracted"],
                purpose: "PAYMENT_HOLIDAY"
            }
        }
    }

    const getFormFields = (fields: any, getOnChange: any, values:any, errors: BoolDictionary) => {
        return fields.map((fieldKey: string) => (
            <DialogField fieldKey={fieldKey} onChange={getOnChange(fieldKey)} error={errors[fieldKey]} key={fieldKey} value={values[fieldKey]} />
        ));
    }

    return (<ConfirmDialog {...props}
                           getParams={getParams}
                           getFormFields={getFormFields}
                           data_provider_endpoint="vault/loan/mutation/"
                           data_provider_call_type="create"
                           label="Payment Holiday"
                           contracted={false}
                           formValidationAlert="Please enter the future payments start date and the effective date of payment holiday"
                           requestErrorMessage="An unexpected error occurred when adding payment holiday. Please report this issue to engineering."
                           redirectTo={'/vault/loan/loans/' + props.id + '/show/expected_schedule/*'}
        />
    )
}

export default AddPaymentHolidayDialog