import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Color} from "@material-ui/lab";
import {Confirm, DataProviderContext, SimpleForm, useNotify} from "react-admin";
import UpdraftDataProvider from "../UpdraftDataProvider";
import {DialogButton} from "./DialogButton";
import Stack from "@mui/material/Stack";
import {BoolDictionary, StringDictionary} from "../custom_types/customTypes";
import uuid from "react-uuid";

interface ConfirmDialogProps {
    id: string;
    icon: any;
    button_type: "list" | "show";
    fields: any;
    getParams: any;
    getFormFields: any;
    contracted:boolean,
    data_provider_endpoint: any;
    data_provider_call_type: "create" | "update"
    label: string;
    formValidationAlert: string;
    requestErrorMessage: string;
    redirectTo: string;
    getInitValues?: any;
    handleClickOpen?: any;
    getConfirmDisabled?: any;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {

    const id = props.id

    let initValues: any = {}
    let initErrors: BoolDictionary = {}
    for (const k in props.fields) {
        initValues[props.fields[k]] = null
        
        // We need this as True by default for DD Change only
        if (props.fields[k] === "contracted") {
            initValues[props.fields[k]] = props.contracted
        }

        initErrors[props.fields[k]] = false
    }
    
    if (props.getInitValues) {
        initValues = props.getInitValues()
    }

    const [open, setOpen] = React.useState(false);
    const [idempotencyKey, setIdempotencyKey] = React.useState<string|null>(null);
    const [values, setValues] = React.useState(initValues);
    const [errors, setErrors] = React.useState(initErrors);
    const [severity, setSeverity] = React.useState<Color>("info");
    const [comment, setComment] = React.useState<String>(props.formValidationAlert);

    const navigate = useNavigate();
    const notify = useNotify();

    const handleClickOpen = async () => {
        setValues(initValues)
        setOpen(true);
        setIdempotencyKey(uuid());
        if (props.handleClickOpen) {
            props.handleClickOpen(getOnChange)
        }
    };

    const getConfirmDisabled = (values: any) => {
        if (!props.getConfirmDisabled) {
            return false
        }
        return props.getConfirmDisabled(values)
    }

    const getOnChange = (fieldKey: string) => {
        return (value: any, error: boolean) => {
            if (error) {
                errors[fieldKey] = false
                setErrors(errors);
            } else {
                values[fieldKey] = value
                setValues(values)
                errors[fieldKey] = false
                setErrors(errors);
                let hasError = false
                for (const e in errors) {
                    if (fieldKey !== e && errors[e]) {
                        hasError = true
                        break
                    }
                }
                hasError ? setSeverity("error") : setSeverity("info")
            }
        };
    }

    const handleConfirm = async () => {
        const msg = getConfirmDisabled(values)
        if (msg.length > 0) {
            setSeverity("error")
            setComment(msg)
            setOpen(true)
            return
        }
        let newErrors = errors
        let hasError = false
        for (const fieldKey in values) {
            if (!values[fieldKey] && fieldKey !== "contracted") {
                hasError = true
                newErrors[fieldKey] = true
            }
        }
        setErrors(newErrors);
        if (hasError) {
            setSeverity("error")
            setOpen(true)
        } else {
            await postFormData(props.getParams(id, values, props), idempotencyKey)
        }
    }

    const handleClose = () => {
        setErrors(initErrors)
        setSeverity("info")
        setOpen(false)
        setIdempotencyKey(null)
        setValues(initValues)
    }

    const usePostFormData = (): [((params: any, idempotencyKey: (string | null)) => Promise<void>), {
        loaded: boolean;
        loading: boolean;
        error: Error | null
    }] => {
        const [loading, setLoading] = React.useState(false);
        const [loaded, setLoaded] = React.useState(false);
        const [error, setError] = React.useState<Error | null>(null);

        const dataProvider = React.useContext(
            DataProviderContext,
        ) as UpdraftDataProvider;

        const postFormData = React.useCallback(async (params: any, idempotencyKey: string|null) => {
            setLoading(true);
            setLoaded(false);
            setError(null);
            try {
                if (props.data_provider_call_type === "create") {
                    await dataProvider.create(
                        props.data_provider_endpoint,
                        params,
                        idempotencyKey
                    )
                } else {
                    await dataProvider.update(
                        props.data_provider_endpoint,
                        params
                    )
                }
                navigate(props.redirectTo)
            } catch (error) {
                if (error instanceof Error) {
                    setError(error);
                } else {
                    setError(new Error(String(error)));
                }
            } finally {
                setLoading(false);
                setLoaded(true);
            }
        }, [setLoading, setLoaded, setError, dataProvider]);

        return [postFormData, {loading, loaded, error}];
    }

    const [postFormData, { loading, loaded, error}] = usePostFormData();

    React.useEffect(() => {
        if (loaded) {
            if (error) {
                notify(props.requestErrorMessage);
            } else {
                setOpen(false);
            }
        }
    }, [error, loaded, notify, props.requestErrorMessage]);

    return (
        <>
            <DialogButton
                label={props.label}
                type={props.button_type}
                onClick={handleClickOpen}
                icon={props.icon}
            />
            <Confirm
                isOpen={open}
                loading={loading}
                title={props.label}
                confirm={loading ? "Working..." : "Confirm"}
                content={
                    <SimpleForm toolbar={false}>
                        <Stack spacing={2}>
                            {props.getFormFields(props.fields, getOnChange, values, errors)}
                            <Alert severity={severity}>
                                {comment}
                            </Alert>
                        </Stack>
                    </SimpleForm>
                }
                onConfirm={() => handleConfirm()}
                onClose={handleClose}
            />
        </>
    );
}

ConfirmDialog.defaultProps = {
    handleClickOpen: null,
    getConfirmDisabled: null
}

export default ConfirmDialog