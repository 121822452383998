import React from "react";
import {CheckboxInput} from "./CheckboxInput";

export const ContractedInput = (props: {onChange: any, error: boolean, value: any}) => {
    const  {onChange, value} = props
    const handleChange = (event: any) => {
        onChange(event.target.checked, false)
    }
    return (
        <CheckboxInput label="Contracted" name="contracted"
                       onChange={handleChange} value={value}
        />
    )
}